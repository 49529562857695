import { Mui,MuiIcons } from "@osu/react-ui";
import ListItemAction from "./ListItemAction";
import { mergeSxStyles } from "../../../styles/util";
import Summary from "./Summary";
import Primary from "./Primary";

function ListItem({ todo = {}, className, sx, headingLevel, ...rest }) {
  let mailto = todo?.email && "mailto:" + todo.email
  if(mailto && (todo?.title || todo?.name)) mailto += `?subject=${todo?.title || todo?.name}`

  return (
    <Mui.ListItem
      className={`display-flex flex-direction-column align-items-flex-start` + (className ? ` ${className}` : '')}
      {...rest}
      sx={mergeSxStyles({
        [`&.${Mui.listItemClasses.divider}`]: {
          paddingTop: 1,
          paddingBottom: 2
        }
      }, sx)}
    >
      <Mui.ListItemText
        primary={
          <Primary
            headingLevel={headingLevel}
            title={todo?.title}
            statusDate={todo?.statusDate}
            statusName={todo?.statusName}
            dueDate={todo?.dueDate}
            name={todo?.name}
            email={todo?.email}
          />
        }
        secondary={(!!todo.summary) && <Summary>{todo.summary}</Summary>}
        secondaryTypographyProps={{
          color: 'textPrimary',
          component: 'div'
        }}
      />
        {todo.url && <ListItemAction
        url={todo.url}
        label={todo.urlLabel}
        startIcon={<MuiIcons.Link />}
      />}
    </Mui.ListItem>
  );
}

export default ListItem;
