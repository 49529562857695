import { Mui, MuiIcons } from "@osu/react-ui";
import ListHeading from "./ListHeading";
import { forwardRef, Fragment } from "react";

const Email = forwardRef((props, ref) => <Fragment>
  <Mui.Typography variant="srOnly">email </Mui.Typography>
  <MuiIcons.Email {...props} ref={ref}/>
</Fragment>)

function Primary({ title, statusDate, statusName, dueDate, name, email, headingLevel }) {
  let mailto = email && "mailto:" + email;
  if (mailto && (title || name))
    mailto += `?subject=${title || name}`;

  return (
    <Fragment>
      <ListHeading 
        title={title} name={name}
        statusName={statusName}
        statusDate={statusDate}
        dueDate={dueDate}
        email={email}
        headingLevel={headingLevel}
        className="margin-bottom-1"
      >
      </ListHeading>
      {mailto && (
        <Mui.Chip
          variant="outlined"
          icon={<Email />}
          component="a"
          color="secondary"
          size="small"
          className="margin-bottom-1 align-self-start font-weight-bold"
          href={mailto}
          label={email}
          clickable
        />
      )}
    </Fragment>
  );
}

export default Primary;
