
import { Mui } from "@osu/react-ui";
import Chip from "../../../Common/components/Chip";
import { useSelector } from "react-redux";
import { compareChips, selectDashboardResponseByKey } from "../../../services/graphql/slices/dashboard";
import { mergeSxStyles } from "../../../styles/util";

const ChipListSummary = (props = {}) => {
  const { type: key, expanded, id } = props;
  const { chips = [] } = useSelector((state) =>
    selectDashboardResponseByKey(state, key),
    compareChips);

    if(!chips?.length) {
    return false
  }
  const idPrefix = key + "-chiplistsummary-";
  let chipProps =  {
    variant: "outlined"
  }
  if(expanded) chipProps.size = "large"
  chipProps.sx = mergeSxStyles((theme) => ({
    marginRight: theme.spacing(),
    marginY: theme.spacing(.5),
  }), chipProps.sx)

  return (
    <Mui.List id={id} className="padding-y-0">
      {chips.map(({ title, snippet }, index) => (
        <Chip
          {...chipProps}
          key={idPrefix + index + "chippie"}
          primary={title}
          secondary={snippet}
          className="margin-right-1"
        />
      ))}
    </Mui.List>
  );
};

export default ChipListSummary;
