import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import DateChip from './DateChip'
import { toDoStatuses } from "../../../services/graphql/slices/todos";
import StyledToDo from './ToDoIcon'

const headings = [...Array(6)]
const variants = headings.map((empty, i) => 'h' + (i +  1))

const ListHeading = ({ title, name, statusName, statusDate, dueDate, email, headingLevel }) => {
  const highlight =
  (statusName ?? "").toLowerCase() === toDoStatuses.keys.incomplete &&
  dueDate;
  let chipId= (title ?? name ?? "") + statusName + dueDate + new Date().toISOString()
  chipId = chipId.replaceAll(" ", "-")

  let typeProps = {
    component: 'span',
    variant: 'h5'
  }
  if(variants.includes(headingLevel)) {
    typeProps.variant = headingLevel
    typeProps.component = headingLevel
  }

  return (
    <Fragment>
      <Mui.Typography
        {...typeProps}
        className="width-100 display-flex align-items-center margin-bottom-1"
      >
        {title ?? name}
        {highlight && <StyledToDo className="margin-left-1" aria-hidden="false" aria-labelledby={chipId} />}
      </Mui.Typography>
      <DateChip
        size="small"
        id={chipId}
        statusName={statusName}
        statusDate={statusDate}
        dueDate={dueDate}
        className={email && "margin-right-1"}
      />
    </Fragment>
  );
};

export default ListHeading;
