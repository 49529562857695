
import { Mui, MuiIcons } from "@osu/react-ui"
import { labels } from "../../util/enums";
import A11yIcon from "../../../Common/components/A11yIcon";
import { mappings } from "../../../styles/icon_colors";
import { accordionSummaryContentHeading, accordionSummaryContentSx } from "./styles";
import { mergeSxStyles } from "../../../styles/util";
import ChipListSummary from "./ChipListSummary";
import { useSelector } from "react-redux";
import { selectDashboardSectionByKey } from "../../../services/graphql/slices/dashboard";
import { Fragment } from "react";

const AccordionSummary = (props = {}) => {
  const { type: key, expanded, sx, ariaControls, buttonId, expandIcon } = props
  const color = mappings[key]
  const idPrefix = key + "-panel-"
  const { section } = useSelector((state) => selectDashboardSectionByKey(state, key), (a, b) => {
    const keyMatches = (a?.key === b?.key)
    const queryMatch = a?.section?.query === b?.section?.query
    return keyMatches && queryMatch
  }) 
  const { Icon } = section ?? {}
  const headingId = idPrefix + "header"
  const chipList = headingId + "chip-list"
  const label = labels[key]

  return ( 
    <Fragment>
      <Mui.Typography component="h3" aria-labelledby={headingId} aria-describedby={chipList} sx={accordionSummaryContentHeading}>
        <Mui.AccordionSummary
          className="width-100"
          sx={mergeSxStyles(accordionSummaryContentSx, sx)}
          aria-labelledby={headingId}
          aria-controls={ariaControls}
          id={buttonId}
          expandIcon={expandIcon}
        >
            <Mui.Typography className="display-flex" component="span" variant={expanded ? "h3" : "subtitle1"} id={headingId} >
              {Icon && <A11yIcon className="margin-right-1" color={color} >
                <Icon fontSize="small" />
              </A11yIcon>}
              {label}
            </Mui.Typography>
            <Mui.Typography variant="srOnly">{" "}-{" "}</Mui.Typography>
          <ChipListSummary id={chipList} expanded={expanded} type={key} />
        </Mui.AccordionSummary>
      </Mui.Typography>
    </Fragment>
  );
};

AccordionSummary.defaultProps = {
  expandIcon: <MuiIcons.ExpandMore />
}

export default AccordionSummary