import { Mui } from "@osu/react-ui";
import BuckeyeIcon from "../../../Assets/Images/Buckeye";
import BuckeyeWhite from "../../../Assets/Images/BuckeyeWhite";
import useXichigan from "../../hooks/useXichigan";

function BuckeyeLeaf() {
  const theme = Mui.useTheme();
  const darkMode = theme?.palette?.mode === "dark";
  const Icon = darkMode ? BuckeyeWhite : BuckeyeIcon;
  const { className, onClick } = useXichigan()

  return (
    <Mui.IconButton sx={{ marginLeft: "auto" }} aria-label={`Toggle Easter Egg`} className={className} onClick={onClick}>
        <Icon
          sx={{
            ml: "auto",
            fontSize: "inherit",
          }}
        />
      </Mui.IconButton>
  );
}

export default BuckeyeLeaf;