
import { Mui, useColors } from "@osu/react-ui"
import { mappings } from "../../../styles/icon_colors";
import { useSelector } from "react-redux";
import { compareData, selectDashboardResponseByKey } from "../../../services/graphql/slices/dashboard";
import AccordionSummary from "./Summary";
import { mergeSxStyles, focusStyles } from "../../../styles/util";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";

export const classes = ({ color, expanded }) => {
  let cls =  {
    accordion: {
      borderLeft: `solid .2rem ${Mui.lighten(color, .5)}`
    }
  }
  if(!expanded) {
    cls.accordion.clipPath = `inset(.5px -1rem -1rem -1rem)`
  }
  
  return cls
}

const hover = ({theme, expanded}) => {
  let styles =  {
    [`& .${Mui.buttonBaseClasses.root}:hover`]: {
      [`.${Mui.accordionSummaryClasses.content} *`]: {
        fontWeight: theme.typography.fontWeightBold,
      }
    },
  }
  if(!expanded) {
    styles[`& .${Mui.buttonBaseClasses.root}:active, & .${Mui.buttonBaseClasses.root}:hover,  & .${Mui.buttonBaseClasses.root}:focus`] = {
      [`.${Mui.accordionSummaryClasses.content}`]: focusStyles
    }
  }
  return styles
}

const Accordion = (props = {}) => {
  const { type: key, sx, onChange, expanded, className } = props
  const { data, section, ...rest } = useSelector((state) => selectDashboardResponseByKey(state, key), compareData)
  const color = mappings[key]
  const Component = section?.Component ?? props?.Component
  const { border } = useColors({ color })
  const { accordion } = classes({ color: border, expanded })
  const controls = key + "controls-accordion"
  const buttonId = key + "button-accordion"
  const { accordionChildren } = usePaperStyleProps()
  const accordionElevation = expanded ? accordionChildren.elevation + 2 : accordionChildren.elevation
  return (
    <Mui.Accordion TransitionProps={{ unmountOnExit: true }} className={className} elevation={accordionElevation} color={color} sx={mergeSxStyles([accordion, (theme) => hover({ theme, expanded })], sx)} onChange={onChange} expanded={expanded}>
      <AccordionSummary buttonId={buttonId} ariaControls={controls} expanded={expanded} type={key} />
      <Mui.AccordionDetails aria-labelledby={buttonId} role="region" id={controls} className="padding-top-0">
        {(Component) && <Component type={key} data={data} expanded={expanded} {...rest} />}
      </Mui.AccordionDetails>
    </Mui.Accordion>
  );
};

export default Accordion