import { Mui } from "@osu/react-ui";
import { useSelector } from "react-redux";
import { selectIncompleteToDos, toDoStatuses } from "../../../services/graphql/slices/todos";
import ListItem from "./ListItem";
import { buildResponsiveStyle } from "../../../util/functions";
import NoToDoItem from "../NoToDoItem";

function List({ headingLevel }) {
  const todos = useSelector(selectIncompleteToDos);
  return (
    <Mui.List
      className="padding-top-0"
      sx={todos.length > 1 && buildResponsiveStyle("300px")}
    >
      {!todos?.length
        ? <NoToDoItem status={toDoStatuses.labels[toDoStatuses.keys.incomplete].toLowerCase()} />
        : todos.map((todo, idx) => {
            return (
              <ListItem
                headingLevel={headingLevel}
                todo={todo}
                key={"to-do-list-item" + encodeURIComponent(todo?.title + idx)}
                divider={idx < todos.length - 1}
              />
            );
          })}
    </Mui.List>
  );
}

export default List;
